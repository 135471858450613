import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import CopyHeroCache from "./components/copyHero/lib/copyHeroCache";
import CopyHero from "./components/copyHero";
type Context = {
  imageUrl: string;
  imageData: string;
  width: number;
  height: number;
  websiteToken: string;
  apiHost?: string;
  userToken?: string;
  color?: string;
  t: number;
};
export default function Home() {
  const [context, setContext] = useState<Context>();
  // 监听父亲页面传递的值
  useEffect(() => {
    const handleMessage = async (e: any) => {
      const { data } = e;
      //排除其他插件的事件
      if (!data.source || data.source !== "CopyHero") {
        return;
      }
      const cache = new CopyHeroCache();
      const cacheItem = cache.getByImageUrl(data.imageUrl);
      //判断图片是否处理过一次，这样可避免用户需要点击图标才能拷贝文本
      if (data.action === "exist") {
        e.source.postMessage(cacheItem ? "true" : "", e.origin);
        return;
      }
      setContext({ ...data, t: new Date().getTime() });
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  if (context) {
    const theme = createTheme({
      palette: {
        success: {
          main: context.color || "#0C68E9",
        },
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <CopyHero
          imageUrl={context.imageUrl}
          imageData={context.imageData}
          width={context.width}
          height={context.height}
          websiteToken={context.websiteToken}
          userToken={context.userToken}
          apiHost={context.apiHost}
          color={context.color}
          t={context.t}
        />
      </ThemeProvider>
    );
  }
  return <div></div>;
}
