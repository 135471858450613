import { md5Str } from "./copyHeroUtils";

export default class CopyHeroCache {
  public read(hash: string) {
    const value = localStorage.getItem("copy_hero");
    if (value) {
      return JSON.parse(value)[hash];
    }
    return null;
  }
  public write(hash: string, data: any) {
    let value = localStorage.getItem("copy_hero") as any;
    if (value) {
      value = JSON.parse(value);
    } else {
      value = {} as any;
    }
    data["t"] = new Date().getTime();
    value[hash] = data;
    // 检查记录数量
    if (Object.keys(value).length >= 50) {
      // 获取所有键并按时间排序
      const keys = Object.keys(value).sort((a: string, b: string) => {
        const itemA = value[a];
        const itemB = value[b];
        return itemA.t - itemB.t;
      });
      // 删除最早的记录
      delete value[keys[0]];
    }
    localStorage.setItem("copy_hero", JSON.stringify(value));
  }
  /**
   * 根据 image url查询是否缓存
   * @param imageUrl
   * @returns
   */
  public getByImageUrl(imageUrl: string) {
    const preImageUrl = imageUrl.split("?")[0];
    const urlHash = md5Str(preImageUrl);
    let value = localStorage.getItem("copy_hero") as any;
    if (value) {
      value = JSON.parse(value);
    } else {
      value = {} as any;
    }
    const keys = Object.keys(value);
    for (let i = 0; i < keys.length; i++) {
      const hash = keys[i] as any;
      const item = value[hash];
      if (item && item.urlHash === urlHash) {
        return { ...item, hash };
      }
    }
  }
}
