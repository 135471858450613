const md5 = require("md5");
export function fillSingleBlock(ctx: any, blockPoints: any, fillStyle: any) {
  ctx.beginPath();
  blockPoints.forEach((point: any, index: any) => {
    if (index === 0) {
      ctx.moveTo(point.x, point.y);
    } else {
      ctx.lineTo(point.x, point.y);
    }
  });
  ctx.closePath();
  ctx.fillStyle = fillStyle;
  ctx.fill();
}

export function rectanglesIntersect(rect1: any, rect2: any) {
  return !(
    rect1.x2 < rect2.x1 ||
    rect1.x1 > rect2.x2 ||
    rect1.y2 < rect2.y1 ||
    rect1.y1 > rect2.y2
  );
}
export function pointIntersect(point: any, rect: any) {
  return !(
    point.x < rect.x1 ||
    point.x > rect.x2 ||
    point.y < rect.y1 ||
    point.y > rect.y2
  );
}

export function getRectangleBounds(points: any) {
  const xCoords = points.map((p: any) => p.x);
  const yCoords = points.map((p: any) => p.y);
  return {
    x1: Math.min(...xCoords),
    y1: Math.min(...yCoords),
    x2: Math.max(...xCoords),
    y2: Math.max(...yCoords),
  };
}
export function isPointNearLine(point: any, linePoints: any) {
  const buffer = 10; // 允许的距离缓冲区
  const yCoords = linePoints.map((p: any) => p.y);
  const minY = Math.min(...yCoords);
  const maxY = Math.max(...yCoords);
  return point.y >= minY - buffer && point.y <= maxY + buffer;
}
export function fillBlock(ctx: any, x: any, y: any, lines: any) {
  for (const line of lines) {
    if (isPointNearLine({ x, y }, line.line_points)) {
      line.blocks.forEach((block: any) => {
        fillSingleBlock(ctx, block.locations, "rgba(0, 0, 255, 0.1)");
        block.filled = true;
      });
      break;
    }
  }
}
export function fillBlock2(ctx: any, blockPoints: any, fillStyle: any) {
  ctx.beginPath();
  blockPoints.forEach((point: any, index: any) => {
    if (index === 0) {
      ctx.moveTo(point.x, point.y);
    } else {
      ctx.lineTo(point.x, point.y);
    }
  });
  ctx.closePath();
  ctx.fillStyle = fillStyle;
  ctx.fill();
}
export function updateBlocksOnMove(
  ctx: any,
  setupCanvas: any,
  startX: any,
  currentX: any,
  startY: any,
  currentY: any,
  lines: any
) {
  setupCanvas();
  const selectionBounds = {
    x1: Math.min(startX, currentX),
    y1: Math.min(startY, currentY),
    x2: Math.max(startX, currentX),
    y2: Math.max(startY, currentY),
  };

  let startLineIndex = -1;
  let lastLineIndex = -1;

  // 计算第一行和最后一行的索引
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    let lineSelected = false;

    for (const block of line.blocks) {
      const blockBounds = getRectangleBounds(block.locations);
      if (rectanglesIntersect(selectionBounds, blockBounds)) {
        lineSelected = true;
        break;
      }
    }

    if (lineSelected) {
      if (startLineIndex === -1) {
        startLineIndex = i;
      }
      lastLineIndex = i;
    }
  }

  // 处理从第一行到倒数第二行
  for (let i = startLineIndex; i < lastLineIndex; i++) {
    const line = lines[i];
    for (const block of line.blocks) {
      block.filled = true;
      fillBlock2(ctx, block.locations, "rgba(0, 0, 255, 0.1)");
    }
  }

  // 处理最后一行
  if (lastLineIndex !== -1) {
    const lastLine = lines[lastLineIndex];
    for (const block of lastLine.blocks) {
      const blockBounds = getRectangleBounds(block.locations);
      if (rectanglesIntersect(selectionBounds, blockBounds)) {
        block.filled = true;
        fillBlock2(ctx, block.locations, "rgba(0, 0, 255, 0.1)");
      }
    }
  }
}
export function setCursorStyle(
  usedApp: boolean,
  canvas: any,
  currentX: number,
  currentY: number,
  lines: any
) {
  //改变鼠标样式
  let isIn = false;
  for (const line of lines) {
    const blockBounds = getRectangleBounds(line.line_points);
    if (pointIntersect({ x: currentX, y: currentY }, blockBounds)) {
      isIn = true;
      break;
    }
  }
  if (isIn) {
    canvas.style.cursor = "text";
  } else {
    canvas.style.cursor = usedApp ? "default" : "crosshair";
  }
}
export function setSpecBlockStyle(
  canvas: any,
  resetCanvas: any,
  ctx: any,
  currentX: number,
  currentY: number,
  lines: any
) {
  //用户触达到特殊的块，对块周围画虚线
  for (const line of lines) {
    const blocks = line.spec_blocks;
    if (!blocks || blocks.length === 0) {
      continue;
    }
    for (const block of blocks) {
      const { locations, type, value } = block;
      const blockBounds = getRectangleBounds(locations);
      if (pointIntersect({ x: currentX, y: currentY }, blockBounds)) {
        resetCanvas();
        //设置鼠标样式
        canvas.style.cursor = "pointer";
        // 设置虚线样式
        ctx.setLineDash([5, 3]);
        // 绘制虚线框
        ctx.beginPath();
        const padding = 5;
        ctx.moveTo(locations[0].x - padding, locations[0].y - padding);
        ctx.lineTo(locations[1].x + padding, locations[1].y - padding);
        ctx.lineTo(locations[2].x + padding, locations[2].y + padding);
        ctx.lineTo(locations[3].x - padding, locations[3].y + padding);
        ctx.closePath();
        ctx.stroke();
        if (type === "email") {
          return "mailto:" + value;
        }
        return value;
      }
    }
  }
  return "";
}
export function md5Str(str: string) {
  return md5(str);
}

type Data = { [key: string]: any } | any[];

function addToNumbers(data: Data, increment: number): any {
  if (Array.isArray(data)) {
    // 处理数组类型
    return data.map((item) => addToNumbers(item, increment));
  } else if (typeof data === "object" && data !== null) {
    // 处理对象类型
    const result: { [key: string]: any } = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        result[key] = addToNumbers(data[key], increment);
      }
    }
    return result;
  } else if (typeof data === "number") {
    // 如果是数字类型，则加上 increment
    const value = data + increment;
    return value;
  } else {
    // 其他类型不做处理，直接返回
    return data;
  }
}
export function decryptNumbers(data: Data, nonce: string) {
  const factory = nonce.charCodeAt(0) + nonce.charCodeAt(nonce.length - 1);
  return addToNumbers(data, -factory);
}
