import { useCallback, useEffect, useState } from "react";
import TradeMarker from "../trade-marker";
import CopyHeroCache from "./lib/copyHeroCache";
import { copyRequest, getCoordinates } from "./lib/request";
import CopyHeroCore from "./lib/copyHeroCore";
import { setApiHost, setWebSiteToken } from "../../config";
import { Alert, Snackbar, styled } from "@mui/material";
import "./index.css";
import { calculateBase64Hash, decryptJwt } from "./lib/utils";
type CopyHeroProps = {
  imageUrl: string;
  imageData: string;
  width: number;
  height: number;
  websiteToken: string;
  apiHost?: string;
  userToken?: string;
  color?: string;
  t: number;
};
export default function CopyHero(props: CopyHeroProps) {
  const {
    imageUrl,
    width,
    height,
    imageData,
    websiteToken,
    apiHost,
    userToken,
    color,
    t,
  } = props;
  const [open, setOpen] = useState(false);
  const [hash, setHash] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const openTip = useCallback(async () => {
    setOpen(true);
    //copy log
    if (hash) {
      copyRequest(hash);
    }
  }, [hash]);
  useEffect(() => {
    setOpen(false);
    //默认每次进入的后都回初始化状态
  }, [imageUrl, t]);
  useEffect(() => {
    const fetch = async () => {
      const hash = await calculateBase64Hash(imageData);
      setHash(hash);
    };
    fetch();
  }, [imageData]);
  const [loading, setLoading] = useState(false);
  //设置 websiteToken
  setWebSiteToken(decryptJwt(websiteToken));
  setApiHost(apiHost);

  useEffect(() => {
    const fetch = async () => {
      //计算是否在 cache 中
      setLoading(true);
      const containerNode = document.getElementById("container") as any;
      const loadingNode = document.getElementById("loading") as any;
      containerNode.style.position = "relative";
      containerNode.style.overflow = "hidden";
      containerNode.style.width = width + "px";
      containerNode.style.height = height + "px";
      const cache = new CopyHeroCache();
      const cacheItem = cache.getByImageUrl(imageUrl);
      const cpt = new CopyHeroCore();
      if (!cacheItem) {
        loadingNode.className = "loading-overlay";
        const responseData = await getCoordinates(
          imageUrl,
          imageData,
          width,
          height
        );
        setLoading(false);
        //移除动画
        loadingNode.className = "";
        if (responseData) {
          cpt.render(
            openTip,
            responseData.hash,
            imageData,
            userToken ? true : false,
            responseData.nonce,
            width,
            height,
            responseData.data,
            responseData.direction
          );
        }
      } else {
        //重绘缓存对象
        setLoading(false);
        cpt.render(
          openTip,
          cacheItem.hash,
          imageData || cacheItem.imageData,
          userToken ? true : false,
          cacheItem.nonce,
          width,
          height
        );
      }
    };
    fetch();
  }, [openTip, imageUrl, width, height, imageData, userToken]);
  const CustomAlert = styled(Alert)(({ theme }) => ({
    "& .MuiAlert-icon": {
      display: "none",
    },
    justifyContent: "center",
    paddingLeft: theme.spacing(2), // 调整 padding 以确保文本居中
  }));

  return (
    <div id="container">
      <canvas id="canvas" />
      {!userToken && (
        <TradeMarker
          color={color}
          imageUrl={imageUrl}
          loading={loading}
          width={width}
          t={t}
        />
      )}
      <div id="loading" />
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <CustomAlert severity="success" variant="filled" onClose={null as any}>
          Copied!
        </CustomAlert>
      </Snackbar>
    </div>
  );
}
