import { useEffect, useState } from "react";

type TradeMarkerProps = {
  color?: string;
  imageUrl?: string;
  loading: boolean;
  t: number;
  width: number;
};
export default function TradeMarker({
  color,
  imageUrl,
  loading,
  t,
  width,
}: TradeMarkerProps) {
  const [visible, setVisible] = useState(true);
  const [showTime, setShowTime] = useState(10);
  const getLogo = () => {
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 714.31 122.17">
    <g fill="${color}">
      <path
        d="M213.85,62.85c0,21.2-17.24,38.44-38.44,38.44s-38.44-17.24-38.44-38.44,17.24-38.44,38.44-38.44,38.44,17.24,38.44,38.44ZM200.12,62.85c0-13.62-11.09-24.71-24.71-24.71s-24.71,11.09-24.71,24.71,11.09,24.71,24.71,24.71,24.71-11.09,24.71-24.71Z" />
      <path
        d="M285.45,30.56c3.73,3.84,6.04,9.01,6.04,14.72,0,8.35-4.94,15.6-12.08,18.89-1.87.88-3.84,1.43-5.93,1.65h-.66v.11h-36.79v35.36h-13.73V24.41h48.44c5.71,0,10.87,2.31,14.61,6.04l.11.11ZM273.7,51.53c2.53-1.1,4.17-3.62,4.17-6.37,0-2.53-1.32-4.28-2.09-5.05-.88-.88-2.53-1.98-4.94-1.98h-34.82v14.17h34.82c.99,0,1.98-.22,2.86-.66v-.11Z" />
      <path
        d="M296.21,101.29h.22l-.22.22v-.22ZM373.09,24.3l-29.76,38.55-1.65,2.09v36.24h-13.73v-36.02l-1.87-2.31-29.77-38.55h17.35l21.09,27.35,21.09-27.35h17.24ZM372.98,101.29h.11v.22l-.11-.22Z" />
      <path d="M461.06,24.41v76.88h-13.73v-35.26h-49.42v35.26h-13.73V24.41h13.73v27.9h49.42v-27.9h13.73Z" />
      <path
        d="M485.77,45.16v7.14h49.42v13.73h-49.42v14.5c0,3.84,3.18,7.03,7.03,7.03h56.12v13.73h-56.12c-11.42,0-20.65-9.34-20.65-20.76v-35.36c0-11.42,9.23-20.76,20.65-20.76h56.12v13.73h-56.12c-3.85,0-7.03,3.18-7.03,7.03Z" />
      <path
        d="M621.95,87.78l7.03,13.73h-15.38l-7.03-13.73-7.36-14.39-.33-.66c-2.42-3.95-6.7-6.59-11.64-6.59h-13.73v35.36h-13.73V24.63h48.54c5.71,0,10.87,2.3,14.61,6.04,3.73,3.73,6.04,8.9,6.04,14.61,0,8.35-4.94,15.6-12.08,18.89-1.87.88-3.85,1.43-5.93,1.65l10.98,21.97ZM608.33,52.41c.99,0,1.98-.22,2.86-.66,2.53-1.1,4.17-3.62,4.17-6.37,0-2.53-1.32-4.28-2.09-5.05-.88-.88-2.53-1.98-4.94-1.98h-34.82v14.17l34.82-.11Z" />
      <path
        d="M714.31,62.85c0,21.2-17.24,38.44-38.44,38.44s-38.44-17.24-38.44-38.44,17.24-38.44,38.44-38.44,38.44,17.24,38.44,38.44ZM700.58,62.85c0-13.62-11.09-24.71-24.71-24.71s-24.71,11.09-24.71,24.71,11.09,24.71,24.71,24.71,24.71-11.09,24.71-24.71Z" />
    </g>
    <path fill="${color}"
      d="M73.63,111.07c-28.26,0-51.04-23.44-49.95-51.94.99-26.07,22.35-47.24,48.43-48.02,11.87-.35,22.83,3.43,31.57,10.01,1.32.99,2.9-.83,1.77-2.03C93.59,6.58,76.5-.93,57.67.09,26.59,1.79,1.5,27.1.07,58.19c-1.62,35.05,26.32,63.97,61.02,63.97,17.48,0,33.24-7.35,44.37-19.12,1.14-1.21-.45-3.01-1.78-2.01-8.36,6.3-18.77,10.04-30.05,10.04Z" />
    <polygon fill="#f7931e"
      points="85.7 101.12 121.79 58.19 106.53 58.15 122.47 27.61 99.64 27.53 83.75 66.87 99.17 66.89 85.7 101.12" />
  </svg>`;
  };
  useEffect(() => {
    setVisible(true);
    setShowTime(10);
  }, [imageUrl, t]);
  useEffect(() => {
    if (loading) {
      return;
    }
    const inter = setInterval(() => {
      setShowTime(showTime - 1);
    }, 1000);
    if (showTime === 0) {
      setVisible(false);
    }
    return () => {
      clearInterval(inter);
    };
  }, [showTime, loading]);
  if (visible) {
    if (width >= 220) {
      return (
        <div
          style={{
            position: "fixed",
            top: 5,
            left: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "white",
            backgroundColor: "black",
            padding: 5,
            borderRadius: 10,
          }}
        >
          <a
            href="https://copyhero.net"
            target="_blank"
            rel="noreferrer"
            style={{
              padding: 5,
              borderRadius: 10,
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "60px",
              }}
              dangerouslySetInnerHTML={{ __html: getLogo() }}
            />
          </a>
          <div
            style={{
              marginLeft: 5,
              fontSize: 14,
            }}
          >
            {loading ? "Please Wait, Preparing..." : "Working for You"}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "fixed",
            top: 5,
            left: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "white",
            backgroundColor: "black",
            padding: 5,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              marginLeft: 5,
              fontSize: 12,
            }}
          >
            {loading ? "CopyHero is preparing" : "CopyHero is Working"}
          </div>
        </div>
      );
    }
  }
  return <div />;
}
