import { base64ToBlob, calculateBase64Hash, removeBase64Prefix } from "./utils";
import CopyHeroCache from "./copyHeroCache";
import { API_HOST, WEBSITE_TOKEN } from "../../../config";
import { md5Str } from "./copyHeroUtils";
import { putFile2S3 } from "../../../utils/putFileToS3";
const cache = new CopyHeroCache();
/**
 * 发送请求
 * @param subPath
 * @param body
 * @returns
 */
export async function request(subPath: string, body: any) {
  const url = `${API_HOST}/${subPath}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${WEBSITE_TOKEN}`,
    },
    body: JSON.stringify(body),
  });
  return await response.json();
}
/**
 * 获得图片的文字位置信息
 * @returns
 */
export async function getCoordinatesFromCache(hash: string) {
  //优先本地缓存
  const cacheData = cache.read(hash);
  if (cacheData) {
    return [cacheData.data, cacheData.direction, cacheData.nonce];
  }
}
/**
 * 获得图片的文字位置信息
 * @param imageUrl 图片原始地址
 * @param imageData 图片原始内容
 * @param websiteToken Site的 nonce
 * @returns
 */
export async function getCoordinates(
  imageUrl: string,
  imageData: string,
  width: number,
  height: number
) {
  const referrer = document.referrer;
  const localUrl = imageUrl.split("?")[0];
  const urlHash = md5Str(localUrl);
  let hash = "";
  if (imageData) {
    hash = await calculateBase64Hash(imageData);
    //优先本地缓存
    const cacheItem = cache.read(hash);
    if (cacheItem) {
      return {
        hash,
        data: cacheItem.data,
        direction: cacheItem.direction,
        urlHash,
        imageData,
        nonce: cacheItem.nonce,
      };
    }
  }
  //从数据库缓存中获得
  let response = await request("get_coordinates_by_hash", {
    hash,
  });
  if (response.code === 200) {
    if (response.data.length > 0) {
      cache.write(hash, {
        data: response.data,
        direction: response.direction,
        urlHash,
        nonce: WEBSITE_TOKEN,
      });
      return {
        hash,
        data: response.data,
        direction: response.direction,
        nonce: WEBSITE_TOKEN,
      };
    }
  }
  //文件转存到 S3 中
  const { content_type, s3_signed_url, s3_temp_key } = await beforeUploadFile();
  const base64Data = removeBase64Prefix(imageData);
  const binaryData = base64ToBlob(base64Data);
  await putFile2S3(s3_signed_url, content_type, binaryData);
  response = await request("get_coordinates", {
    referrer: referrer || window.location.href,
    image_url: imageUrl,
    s3_temp_key,
    hash,
  });
  if (response.code === 200) {
    cache.write(hash, {
      data: response.data,
      direction: response.direction,
      urlHash,
      nonce: WEBSITE_TOKEN,
    });
    return {
      hash,
      data: response.data,
      direction: response.direction,
      nonce: WEBSITE_TOKEN,
    };
  }
}
export async function beforeUploadFile() {
  return await request("before_upload_file", {});
}
export async function copyRequest(hash: string) {
  return await request("copy", { hash });
}
