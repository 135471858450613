const CryptoJS = require("crypto-js");

export async function calculateBase64Hash(imageData: string) {
  const base64String = removeBase64Prefix(imageData);
  // 解码 Base64 字符串为 WordArray 对象
  const wordArray = CryptoJS.enc.Base64.parse(base64String);

  // 计算 SHA-256 哈希值
  const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);

  return hash;
}
export function removeBase64Prefix(base64String: string) {
  return base64String.replace(/^data:image\/\w+;base64,/, "");
}
export function base64ToBlob(base64String:string) {
  const contentType = "image/jpeg";
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}
export function decryptJwt(data:string){
  const key = "/GPnRkIqQPCQln0avdq6L+RbWZZWKpmicJ5NVKY7w+0="
  const keyBytes = CryptoJS.enc.Base64.parse(key);
  const decrypted = CryptoJS.AES.decrypt(data, keyBytes, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}