function ajax(url: string, options?: any) {
  return new Promise((resolve, reject) => {
    const {
      async = true,
      data = null,
      headers = {},
      method = 'get',
      timeout = 0,
      onprogress,
      onload,
    } = (options || {}) as any;

    const xhr = new XMLHttpRequest();

    let timerId = 0 as any;

    if (timeout) {
      timerId = setTimeout(() => {
        reject(new Error(`the request timeout ${timeout}ms`));
      }, timeout);
    }

    xhr.onerror = () => {
      reject(new Error('unknown error'));
    };

    if (xhr.upload) {
      if (onprogress) {
        // Note: the progress event must be located before the xhr.open method
        xhr.upload.onprogress = onprogress;
      }

      if (onload) {
        xhr.upload.onload = onload;
      }
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (timeout) clearTimeout(timerId);
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr);
        } else {
          reject(new Error('the request is error'));
        }
      }
    };

    xhr.open(method, url, async);

    Object.keys(headers).forEach((key) => {
      xhr.setRequestHeader(key, headers[key]);
    });

    try {
      xhr.send(data);
    } catch (err) {
      reject(err);
    }
  });
}
/**
 * Put file to s3 via signedUrl
 */
export const putFile2S3 = async (
  signedUrl: string,
  contentType: string,
  file: any,
  options?: any
) => {
  await ajax(signedUrl, {
    method: 'PUT',
    data: file,
    headers: { 'Content-Type': contentType },
    timeout: options ? options.timeout || 60 * 60 * 1000 : 60 * 60 * 1000,
    onprogress: options ? options.onprogress : '',
    onload: options ? options.onload : '',
  });
};
