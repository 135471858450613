import { Line } from "./types";
import { getCoordinatesFromCache } from "./request";
import {
  decryptNumbers,
  fillBlock,
  setCursorStyle,
  setSpecBlockStyle,
  updateBlocksOnMove,
} from "./copyHeroUtils";
const eventHandlers = {
  mousedown: null,
  dblclick: null,
  mousemove: null,
  mouseup: null,
} as any;

export default class CopyHeroCore {
  private canvas: any;
  private ctx: any;
  private image: any;
  private width: number = 0;
  private height: number = 0;
  private scale: number = 1;
  private hash: string = "";
  private openTip: any = null;
  private lines: Array<Line> = [];
  private startX: number = 0;
  private startY: number = 0;
  private currentX: number = 0;
  private currentY: number = 0;
  private dragging: boolean = false;
  private initialized: boolean = false;
  private nextUrl: string = "";
  private direction: number = 0;
  private usedApp: boolean = false;
  /**
   * 更新 Coordinates
   * @param lines
   */
  public render(
    openTip: any,
    hash: string,
    imageData: string,
    usedApp: boolean,
    nonce: string,
    width?: number,
    height?: number,
    lines?: Array<Line>,
    direction?: number
  ) {
    const canvas = document.getElementById("canvas") as any;
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.usedApp = usedApp;
    this.canvas.style.cursor = !usedApp ? "crosshair" : "default";
    this.width = width || 0;
    this.height = height || 0;
    this.image = new Image();
    this.image.src = imageData;
    this.hash = hash;
    this.lines = decryptNumbers(lines || [], nonce);
    this.direction = direction || 0;
    this.initialized = false;
    this.nextUrl = "";
    this.image.onload = this.handleLoadedImage;
    this.openTip = openTip;
    this.bindEvent("mousedown", this.handleMousedown);
    this.bindEvent("dblclick", this.handleDblclick);
    this.bindEvent("mousemove", this.handleMousemove);
    this.bindEvent("mouseup", this.handleMouseup);
  }
  private bindEvent(eventType: string, handler: any) {
    // 如果已经绑定了该类型的事件处理程序，先解绑
    if (eventHandlers[eventType]) {
      this.canvas.removeEventListener(eventType, eventHandlers[eventType]);
    }
    // 绑定新的事件处理程序
    this.canvas.addEventListener(eventType, handler);
    // 保存处理程序的引用
    eventHandlers[eventType] = handler;
  }
  /**
   * 更新 block status
   * @param coordinates
   */
  private resetBlockStatus() {
    for (let i = 0; i < (this.lines || []).length; i++) {
      const { blocks } = this.lines[i];
      for (const block of blocks) {
        block.filled = false;
      }
    }
  }
  /**
   * 更新 Coordinates
   * @param coordinates
   */
  private setScale() {
    //对 coordinate 数字缩放
    this.resetBlockStatus();
    for (let i = 0; i < (this.lines || []).length; i++) {
      const { blocks, line_points, spec_blocks } = this.lines[i];
      for (const block of blocks) {
        for (const point of block.locations) {
          point.x *= this.scale;
          point.y *= this.scale;
        }
      }
      for (const point of line_points) {
        point.x *= this.scale;
        point.y *= this.scale;
      }
      if (spec_blocks) {
        for (const block of spec_blocks) {
          for (const point of block.locations) {
            point.x *= this.scale;
            point.y *= this.scale;
          }
        }
      }
    }
  }
  private handleText = async () => {
    //发送到后端获得文本
    let totalText = "";
    for (const line of this.lines) {
      const { blocks, words } = line;
      let allSelected = true;
      for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        if (!block.filled) {
          allSelected = false;
        }
      }
      if (allSelected) {
        //整行被选中
        totalText += words + "\n";
      } else {
        //最后一行部分被选中,寻找最后的单词出现的位置
        let lastPos = 0;
        for (let i = 0; i < blocks.length; i++) {
          const block = blocks[i];
          if (block.filled) {
            lastPos = words.indexOf(block.word, lastPos) + block.word.length;
          }
        }
        if (lastPos > 0) {
          totalText += words.substring(0, lastPos);
        }
      }
    }
    if (totalText.length > 0) {
      this.copyText(totalText);
    }
  };
  /**
   * 加载图片
   */
  private handleLoadedImage = async () => {
    this.resetCanvas();
    if (this.lines.length === 0) {
      const result = await getCoordinatesFromCache(this.hash);
      if (result) {
        this.lines = decryptNumbers(result[0], result[2]);
        this.direction = result[1];
      }
    }
    if (this.initialized === false) {
      this.initialized = true;
      this.setScale();
    }
  };
  /**
   * 初始化面板
   */
  private resetCanvas = () => {
    //初始化所有 block 的选中状态
    for (const line of this.lines) {
      for (const block of line.blocks) {
        block.filled = false;
      }
    }
    // 在 canvas 上绘制图片
    if (this.width && this.height) {
      // 计算缩放比例
      this.scale = Math.min(
        this.width / this.image.width,
        this.height / this.image.height
      );
      // 计算缩放后的宽高
      const newWidth = this.image.width * this.scale;
      const newHeight = this.image.height * this.scale;
      this.canvas.width = this.image.width;
      this.canvas.height = this.image.height;
      //先隐藏加载完毕后，显示避免闪屏
      this.canvas.style.display = "none";
      // 在新canvas上绘制缩放后的图像
      this.ctx.drawImage(
        this.image,
        0,
        0,
        this.image.width,
        this.image.height,
        0,
        0,
        this.image.width,
        this.image.height
      );
      this.canvas.width = newWidth;
      this.canvas.height = newHeight;
      this.canvas.style.display = "block";
    } else {
      //默认图片大小
      this.canvas.width = this.image.width;
      this.canvas.height = this.image.height;
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(
        this.image,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
    }
  };

  /**
   * copy text
   * @param text
   */
  private copyText = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    if (text) {
      this.openTip();
    }
  };
  private handleMousedown = (event: any) => {
    if (this.nextUrl) {
      window.open(this.nextUrl, "_blank");
    } else {
      this.resetCanvas();
      const rect = this.canvas.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
      this.dragging = true;
    }
  };
  private handleMousemove = (event: any) => {
    const rect = this.canvas.getBoundingClientRect();
    this.currentX = event.clientX - rect.left;
    this.currentY = event.clientY - rect.top;
    this.nextUrl = "";
    //设置鼠标样式
    setCursorStyle(
      this.usedApp,
      this.canvas,
      this.currentX,
      this.currentY,
      this.lines
    );
    if (!this.dragging) {
      //检测特殊 block
      this.nextUrl = setSpecBlockStyle(
        this.canvas,
        this.resetCanvas,
        this.ctx,
        this.currentX,
        this.currentY,
        this.lines
      );
      return;
    }
    //清空所有 block 选中状态
    this.resetBlockStatus();
    updateBlocksOnMove(
      this.ctx,
      this.resetCanvas,
      this.startX,
      this.currentX,
      this.startY,
      this.currentY,
      this.lines
    );
  };
  /**
   * 处理dblclick事件
   * @param e
   */
  private handleDblclick = (e: any) => {
    if (this.direction === 0) {
      this.resetCanvas();
      this.resetBlockStatus();
      const rect = this.canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      fillBlock(this.ctx, x, y, this.lines);
      //设置 text
      this.handleText();
    }
  };
  private handleMouseup = () => {
    this.dragging = false;
    //设置 text
    this.handleText();
  };
}
